import React, { useState, useEffect } from 'react'
import { AppWrapp, MotionWrap } from '../../wrapper'
import { motion } from 'framer-motion'
import { client, urlFor } from '../../client'

import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import './testimonial.scss'

const Testimonial = () => {
  const [brands, setBrands] = useState([])
  const [testimonials, setTestimonials] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleClick = (index) => {
    setCurrentIndex(index)
  }
  useEffect(() => {
    const brandsQuery = '*[_type == "brands"]'
    const testimonialQuery = '*[_type == "testimonials"]'

    client.fetch(testimonialQuery).then((data) => {
      setTestimonials(data)
    })

    client.fetch(brandsQuery).then((data) => {
      setBrands(data)
    })
  }, [])
  const testIndex = testimonials[currentIndex]
  return (
    <>
      {testimonials.length && (
        <>
          <div className="app__testimonial-item app__flex">
            <img src={urlFor(testIndex.imgurl)} alt="testimonials" />
            <div className="app__testimonial-content">
              <p className="p-text">{testIndex.feedback}</p>
              <div>
                <h4 className="bold-text">{testIndex.name}</h4>
                <h5 className="p-text">{testIndex.company}</h5>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="app__testimonial-btns app__flex">
        <div
          className="app__flex"
          onClick={() =>
            handleClick(
              currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1,
            )
          }
        >
          <HiChevronLeft />
        </div>
        <div
          className="app__flex"
          onClick={() =>
            handleClick(
              currentIndex >= testimonials.length - 1 ? 0 : currentIndex + 1,
            )
          }
        >
          <HiChevronRight />
        </div>
      </div>
      <div className="app__testimonials-brand app__flex">
        {brands.map((brand) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: 'tween' }}
            key={brand._id}
          >
            <img src={urlFor(brand.imgUrl)} alt={brand.name} />
          </motion.div>
        ))}
      </div>
    </>
  )
}

export default AppWrapp(
  MotionWrap(Testimonial, 'app__testimonial'),
  'testimonial',
  'app__primarybg',
)
