import React from 'react'
import { NavigationDots, SocialMedia } from "../components";
const AppWrapp = (Component, idName, classNames) => function HOC() {
    return (
        <section className={`app__container ${classNames}`} id={idName} >
            <div className='navigator'>
                <NavigationDots IDName={idName} />
                <SocialMedia />
            </div>
            <Component />
        </section>




        // <div id={idName} className={`app__container ${classNames}`}>
        //     <div className='leftSide__container'>
        //         <NavigationDots IDName={idName} />
        //         <SocialMedia />
        //     </div>
        //     <div className="app__wrapper aspp__flex">
        //         <Component />
        //         <div className="copyright">
        //             <p className="p-text">@2022 Israel Akinwumi</p>
        //             <p className="p-text">All rights reserved</p>
        //         </div>
        //     </div>
        //     <div className="rightSide__container">
        //         <Sidecontroller />
        //     </div>
        // </div>
    )
}

export default AppWrapp